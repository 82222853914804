import React, { useState } from 'react';
import logo from './svg-3.png';
import integration from './integration.png';
import SEO from './SEO';
import './App.css';
import {
  Divider,
  MantineProvider,
  createStyles,
  Title,
  Card,
  List,
  ListItem,
  Text,
  Image,
  Code,
  Container,
  Space,
  CopyButton,
  TextInput,
  Accordion,
  Button,
  Textarea,
  Group,
  ScrollArea,
  ActionIcon,
  Alert,
} from '@mantine/core';
import { IconAlertCircle, IconCopy } from '@tabler/icons-react';

const downloadCodeAsFile = (filename, content) => {
  const element = document.createElement("a");
  const file = new Blob([content], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  document.body.removeChild(element);
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    textAlign: 'center', // Centers text and inline elements
  },
  logo: {
    display: 'block',       // Ensures the image behaves like a block element
    margin: '0 auto',       // Centers the block element horizontally
    maxWidth: '280px',      // Optional: Set max width to maintain proportions
    height: 'auto',         // Maintain aspect ratio
  },
  section: {
    marginBottom: theme.spacing.xl,
  },
  cardSection: {
    backgroundColor: theme.colors.dark[7], // Matches the dark theme
    border: `1px solid ${theme.colors.dark[4]}`,
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    marginBottom: theme.spacing.md,
    boxShadow: theme.shadows.sm,
  },
  codeBlock: {
    backgroundColor: theme.colors.dark[9], // Dark background
    color: theme.white, // White text for better contrast
    borderRadius: theme.radius.sm,
    padding: theme.spacing.md,
    fontSize: theme.fontSizes.sm,
    lineHeight: 1.5,
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    marginBottom: theme.spacing.md,
    textAlign: 'left',
  },
  footer: {
    marginTop: theme.spacing.xl,
    textAlign: 'center',
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[6],
  },
}));

function App() {

  const { classes } = useStyles();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [url, setUrl] = useState('');
  const [config, setConfig] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const fallbackEmail = 'youremail@example.com';
  const emailToUse = email || fallbackEmail;
  const curlFreeUrl = `curl https://urlyup.com/free?email=${emailToUse}`;
  const debianInstallCommand = `wget -O - https://urlyup.com/debian.sh | EMAIL=${emailToUse} bash`;

  React.useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/urlyup.matomo.cloud/container_Hsjwy1RL.js'; s.parentNode.insertBefore(g,s);
   }, []);

  const validateEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(!isValid);
    setEmail(value);
  };

  const fetchWireGuardConfig = async () => {
    if (!email || emailError) {
      setError('Please enter a valid email address before proceeding.');
      return;
    }



    setLoading(true);
    setError('');

    try {
      const response = await fetch(`https://urlyup.com/free?email=${email}`);
      const data = await response.json();

      if (data && data.error) {
        throw new Error(data.error || 'An error occurred while fetching the configuration.');
      }

      setUrl(data.url_to_reach_host || '');
      setConfig(data.config_for_wireguard || '');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderCopyableCode = (code) => (
    <div className={classes.codeBlock}>
      <Code>{code}</Code>
      <CopyButton value={code}>
        {({ copy, copied }) => (
          <IconCopy
            size={20}
            onClick={copy}
            style={{ cursor: 'pointer', color: copied ? 'teal' : 'white' }}
            title={copied ? 'Copied!' : 'Copy to clipboard'}
          />
        )}
      </CopyButton>
    </div>
  );

  return (
    <MantineProvider
      theme={{
        colorScheme: 'dark',
        fontFamily: 'Roboto, sans-serif',
        headings: { fontFamily: 'Roboto, sans-serif' },
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <SEO />
      <Container size={1200} className={classes.wrapper}>
        {/* Logo */}
        <Image
  className={classes.logo}
  src={logo}
  alt="UrlyUp Logo"
/>
        <Space h="lg" />

        {/* Rest of the content */}
        <Card shadow="sm" padding="lg" radius="md" withBorder className={classes.section}>
          <Title>🦹🏼‍♂️</Title>
          <Space h="md" />
          <Text>
          Share your locally hosted website or application securely with clients or stakeholders, eliminating the need for a staging environment. Avoid the overhead of deploying incomplete work for feedback and focus on accelerating your development process. By enabling instant and secure access to your local app, you can streamline feedback loops, improve collaboration, and iterate faster—all without disrupting your flow or requiring complex setup.
          </Text>
          <Space h="md" />
          <Image
  className={classes.logo}
  src={integration}
  alt="UrlyUp Integration"
/>
        </Card>


        {/* Get Your Free URL Section */}
        <Card shadow="sm" padding="lg" radius="md" withBorder className={classes.section}>
          <Title>Get Your Free URL</Title>

        


<Accordion>

    {/* howitworks Accordion */}
    <Accordion.Item value="howitworks">
    <Accordion.Control>How does it work?</Accordion.Control>
    <Accordion.Panel>
    <Container>
      <Text  style={{ textAlign: 'justify' }} mt="md">
        UrlyUp.com leverages the powerful WireGuard stack, seamlessly integrated into Linux, to assign a private IP
        to your device. This private IP is generated through a WireGuard configuration uniquely assigned to you when
        you request a new URL on UrlyUp.com.
      </Text>
      <Text  style={{ textAlign: 'justify' }} mt="md">
       UrlyUp.com directly links your private URL to your private IP whenever WireGuard is active on your device. By default, the URL forwards all connections to port 3000 on your private IP. However, you can easily customize the port by specifying it, followed by a hyphen (-), before the URL host.

For example:
8080-RT4T-free.urlyup.com will forward traffic to port 8080 of your private IP on the device running WireGuard.

This flexible setup ensures that your private connections are always routed exactly where you need them.
      </Text>

      <Title  style={{ textAlign: 'justify' }} order={4} mt="lg">Setup Instructions</Title>

      <Title  style={{ textAlign: 'justify' }}order={5} mt="md">For Linux Users</Title>
      <Text  style={{ textAlign: 'justify' }}>
        Setup is effortless with a single-line terminal command that automates the configuration process: Choose Linux Install below.
      </Text>

      <Title  style={{ textAlign: 'justify' }} order={5} mt="md">For Mac, iOS, and Windows Users</Title>
      <List withPadding>
        <List.Item  style={{ textAlign: 'justify' }}>
          If WireGuard isn’t already installed, click on the provided <strong>“Install"</strong> guide below for your operating system.
        </List.Item>
        <List.Item  style={{ textAlign: 'justify' }}>
          Once installed, copy and paste the on-screen configuration, you will obtain in the next step, into the WireGuard app to establish your private connection.
        </List.Item>
      </List>
      <Text mt="md" style={{ textAlign: 'justify' }}>
        You can find official WireGuard installation links for various operating systems in the section below.
      </Text>

      <Divider my="lg" />

      <Title  style={{ textAlign: 'justify' }} order={4}>Why do we need your email?</Title>
      <Text  style={{ textAlign: 'justify' }}>
        Your email is required to ensure that only you can access your unique configuration. If you don’t confirm your request,
        we’ll automatically delete the configuration to safeguard your security.
      </Text>
    </Container>

    </Accordion.Panel>
  </Accordion.Item>
  {/* Mac Accordion */}
  <Accordion.Item value="mac">
    <Accordion.Control>Mac install</Accordion.Control>
    <Accordion.Panel>
    <Space h="md" />
          <TextInput
            type="email"
            label="✉️ Enter your email address to verify it's you"
            placeholder="youremail@example.com"
            value={email}
            onChange={(event) => validateEmail(event.target.value)}
            error={emailError ? 'Please enter a valid email address' : null}
            required
          />
          <Space h="md" />

          {error && (
            <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
              {error}
            </Alert>
          )}
      <Text style={{ textAlign: 'justify' }}>

        Install WireGuard on Mac with the Mac installer available here:{" "}
        <a href="https://itunes.apple.com/us/app/wireguard/id1451685025?ls=1&mt=12" target="_blank" rel="noopener noreferrer">
          Mac WireGuard Installer
        </a>
      </Text>
      <Space h="md" />
      <Button onClick={fetchWireGuardConfig} loading={loading}>
        Fetch WireGuard Configuration
      </Button>
      <Space h="md" />
      {url && (
        <div className={classes.cardSection}>
          <Group spacing="xs" align="center">
            <ActionIcon variant="light" size="lg">
              <CopyButton value={url}>
                {({ copy, copied }) => (
                  <IconCopy
                    size={18}
                    onClick={copy}
                    title={copied ? 'Copied!' : 'Copy URL'}
                  />
                )}
              </CopyButton>
            </ActionIcon>
            <Text weight={500}>Public URL:</Text>
            <Text className={classes.codeBlock}>{url}</Text>
          </Group>
        </div>
      )}
      {config && (
        <div className={classes.cardSection}>
          <Group spacing="xs" align="flex-start">
            <ActionIcon variant="light" size="lg">
              <CopyButton value={config}>
                {({ copy, copied }) => (
                  <IconCopy
                    size={18}
                    onClick={copy}
                    title={copied ? 'Copied!' : 'Copy Configuration'}
                  />
                )}
              </CopyButton>
            </ActionIcon>
            <div style={{ flex: 1, backgroundColor: '#1a1b1e' }}>
              <Text weight={500} mb="xs" style={{ textAlign: 'justify' }}>
                WireGuard Configuration:
              </Text>
              <ScrollArea style={{ maxHeight: 450 }} offsetScrollbars>
                <div className={classes.codeBlock}>{config}</div>
              </ScrollArea>
            </div>
          </Group>
        </div>
      )}
    </Accordion.Panel>
  </Accordion.Item>

  {/* Microsoft Windows Accordion */}
  <Accordion.Item value="windows">
    <Accordion.Control>Microsoft Windows install</Accordion.Control>
    <Accordion.Panel>
    <Space h="md" />
          <TextInput
            type="email"
            label="✉️ Enter your email address to verify it's you"
            placeholder="youremail@example.com"
            value={email}
            onChange={(event) => validateEmail(event.target.value)}
            error={emailError ? 'Please enter a valid email address' : null}
            required
          />
          <Space h="md" />

          {error && (
            <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
              {error}
            </Alert>
          )}
      <Text style={{ textAlign: 'justify' }}>
        Install WireGuard on Microsoft Windows with the Windows installer available here:{" "}
        <a href="https://download.wireguard.com/windows-client/" target="_blank" rel="noopener noreferrer">
          Microsoft Windows WireGuard Installer
        </a>
      </Text>
      <Space h="md" />
      <Button onClick={fetchWireGuardConfig} loading={loading}>
        Fetch WireGuard Configuration
      </Button>
      <Space h="md" />
      {url && (
        <div className={classes.cardSection}>
          <Group spacing="xs" align="center">
            <ActionIcon variant="light" size="lg">
              <CopyButton value={url}>
                {({ copy, copied }) => (
                  <IconCopy
                    size={18}
                    onClick={copy}
                    title={copied ? 'Copied!' : 'Copy URL'}
                  />
                )}
              </CopyButton>
            </ActionIcon>
            <Text weight={500}>Public URL:</Text>
            <Text className={classes.codeBlock}>{url}</Text>
          </Group>
        </div>
      )}
      {config && (
        <div className={classes.cardSection}>
          <Group spacing="xs" align="flex-start">
            <ActionIcon variant="light" size="lg">
              <CopyButton value={config}>
                {({ copy, copied }) => (
                  <IconCopy
                    size={18}
                    onClick={copy}
                    title={copied ? 'Copied!' : 'Copy Configuration'}
                  />
                )}
              </CopyButton>
            </ActionIcon>
            <div style={{ flex: 1, backgroundColor: '#1a1b1e' }}>
              <Text weight={500} mb="xs" style={{ textAlign: 'justify' }}>
                WireGuard Configuration:
              </Text>
              <ScrollArea style={{ maxHeight: 450 }} offsetScrollbars>
                <div className={classes.codeBlock}>{config}</div>
              </ScrollArea>
            </div>
          </Group>
        </div>
      )}
    </Accordion.Panel>
  </Accordion.Item>




  {/* Linux Accordion */}
  <Accordion.Item value="linux">
    <Accordion.Control>Linux install</Accordion.Control>
    <Accordion.Panel>
    <Space h="md" />
          <TextInput
            type="email"
            label="✉️ Enter your email address to verify it's you"
            placeholder="youremail@example.com"
            value={email}
            onChange={(event) => validateEmail(event.target.value)}
            error={emailError ? 'Please enter a valid email address' : null}
            required
          />
          <Space h="md" />

          {error && (
            <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
              {error}
            </Alert>
          )}
      <Text style={{ textAlign: 'justify' }}>
        After entering your email: Press the copy button and paste into your terminal:
      </Text>
      {renderCopyableCode(debianInstallCommand)}
      <Text style={{ textAlign: 'justify' }}>
        Once you run the command successfully, your private URL will be generated and displayed. Be sure to check your email 
        (including your spam folder) to verify ownership and keep the link active. You can then open the URL in your browser 
        to see a basic website hosted behind your secure URL.
      </Text>
      <Space h="lg" />
      <Text style={{ textAlign: 'justify' }}>📖 Usage Commands:</Text>
      <Space h="md" />
      <Text style={{ textAlign: 'justify' }}>To find your private IP:</Text>
      {renderCopyableCode('wg show wg0')}
      <Text style={{ textAlign: 'justify' }}>To disable the URL:</Text>
      {renderCopyableCode('wg-quick down wg0')}
      <Text style={{ textAlign: 'justify' }}>To re-enable the URL:</Text>
      {renderCopyableCode('wg-quick up wg0')}
    </Accordion.Panel>
  </Accordion.Item>

  <Accordion.Item value="ios">
  <Accordion.Control>iOS Install</Accordion.Control>
  <Accordion.Panel>
  <TextInput
            type="email"
            label="✉️ Enter your email address to verify it's you"
            placeholder="youremail@example.com"
            value={email}
            onChange={(event) => validateEmail(event.target.value)}
            error={emailError ? 'Please enter a valid email address' : null}
            required
          />
          <Space h="md" />

          {error && (
            <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
              {error}
            </Alert>
          )}
    <Text>
      Install WireGuard on iOS using the app available on the{" "}
      <a
        href="https://apps.apple.com/us/app/wireguard/id1441195209"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
          alt="Download on the Apple App Store"
          style={{ width: '150px', display: 'block', margin: '0 auto' }}
        />
      </a>
    </Text>
    <Space h="md" />
    <Button onClick={fetchWireGuardConfig} loading={loading}>
      Fetch WireGuard Configuration
    </Button>
    <Space h="md" />
    {url && (
      <div className={classes.cardSection}>
        <Group spacing="xs" align="center">
          <ActionIcon variant="light" size="lg">
            <CopyButton value={url}>
              {({ copy, copied }) => (
                <IconCopy
                  size={18}
                  onClick={copy}
                  title={copied ? "Copied!" : "Copy URL"}
                />
              )}
            </CopyButton>
          </ActionIcon>
          <Text weight={500}>Public URL:</Text>
          <Text className={classes.codeBlock}>{url}</Text>
        </Group>
      </div>
    )}
    {config && (
      <div className={classes.cardSection}>
        <Group spacing="xs" align="flex-start">
          <ActionIcon variant="light" size="lg">
            <CopyButton value={config}>
              {({ copy, copied }) => (
                <IconCopy
                  size={18}
                  onClick={copy}
                  title={copied ? "Copied!" : "Copy Configuration"}
                />
              )}
            </CopyButton>
          </ActionIcon>
          <div style={{ flex: 1, backgroundColor: "#1a1b1e" }}>
            <Text weight={500} mb="xs">
              WireGuard Configuration:
            </Text>
            <ScrollArea style={{ maxHeight: 450 }} offsetScrollbars>
              <div className={classes.codeBlock}>{config}</div>
            </ScrollArea>
            <Button
              variant="light"
              size="xs"
              onClick={() =>
                downloadCodeAsFile("wireguard-config.txt", config)
              }
              mt="md"
            >
              Download Configuration
            </Button>
          </div>
        </Group>
      </div>
    )}
  </Accordion.Panel>
</Accordion.Item>


  {/* API Accordion */}
  <Accordion.Item value="api">
    <Accordion.Control>API</Accordion.Control>
    <Accordion.Panel>
    <Space h="md" />
          <TextInput
            type="email"
            label="✉️ Enter your email address to verify it's you"
            placeholder="youremail@example.com"
            value={email}
            onChange={(event) => validateEmail(event.target.value)}
            error={emailError ? 'Please enter a valid email address' : null}
            required
          />
          <Space h="md" />

          {error && (
            <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
              {error}
            </Alert>
          )}
      <Text style={{ textAlign: 'justify' }}>
        Use this command to get a free URL and return a self-descriptive JSON:
      </Text>
      {renderCopyableCode(curlFreeUrl)}
    </Accordion.Panel>
  </Accordion.Item>

  {/* About the Service Accordion */}
  <Accordion.Item value="about">
    <Accordion.Control>About the service</Accordion.Control>
    <Accordion.Panel>
      <Text style={{ textAlign: 'justify' }}>
        UrlyUp.com is a tool designed to make exposing locally hosted services to the internet simple and secure over the WireGuard protocol. 
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        It eliminates the need for a public IP, complex network configurations, or additional infrastructure, providing a secured public URL with just a few steps.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        Ideal for developers, it allows seamless testing of webhooks, showcasing projects, or sharing application previews. With built-in encryption and reliable access, 
        UrlyUp.com ensures your local services are accessible from anywhere while maintaining security and ease of use.
      </Text>
    </Accordion.Panel>
  </Accordion.Item>

  <Accordion.Item value="tos">
  <Accordion.Control>Terms of Service</Accordion.Control>
  <Accordion.Panel>
    <Text style={{ textAlign: 'justify' }}>
      The services provided by UrlyUp are offered on an "as-is" and "as-available" basis, with no warranties or guarantees of any kind. 
      By using this service, you acknowledge that UrlyUp is not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the service.
    </Text>
    <Text style={{ textAlign: 'justify' }}>
      The service is provided free of charge, and while we aim for reliability and security, no uptime or performance guarantees are made. 
      Users are solely responsible for their hosted applications and any associated data shared through the service.
    </Text>
    <Text style={{ textAlign: 'justify' }}>
      UrlyUp expects reasonable use of the service, and any use must comply with the laws of the United States, including but not limited to laws regarding data protection, intellectual property, and cybersecurity.
      Abuse of the service, including activities that are illegal, harmful, or disruptive, is strictly prohibited and may result in termination of access.
    </Text>
    <Text style={{ textAlign: 'justify' }}>
      UrlyUp reserves the right to modify or terminate the service at any time without notice. Continued use of the service constitutes acceptance of these terms.
    </Text>
    <Text style={{ textAlign: 'justify' }}>
      If you do not agree with these terms, please refrain from using the service.
    </Text>
  </Accordion.Panel>
</Accordion.Item>

  {/* About WireGuard Accordion */}
  <Accordion.Item value="aboutwireguard">
    <Accordion.Control>About WireGuard</Accordion.Control>
    <Accordion.Panel>
      <Text style={{ textAlign: 'justify' }}>
        WireGuard is a modern, high-performance encryption protocol designed for simplicity, speed, and security. Integrated directly into the Linux kernel, it offers minimal overhead and exceptional performance compared to traditional VPN protocols.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        Its streamlined codebase enhances security by reducing potential vulnerabilities. WireGuard is not only part of Linux but is also available as easy-to-use apps for Apple and Windows platforms, making it widely accessible for various devices. 
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        Whether for secure remote access or private networking, WireGuard combines cutting-edge encryption with unmatched ease of use and portability.
      </Text>
    </Accordion.Panel>
  </Accordion.Item>

  {/* Compare Accordion */}
  <Accordion.Item value="compare">
    <Accordion.Control>Compare</Accordion.Control>
    <Accordion.Panel>
      <Text style={{ textAlign: 'justify' }}>
        - Completely free and you can keep the URL without the need to generate a new one.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        - One URL for as many ports as needed (API/Web) without reconfiguring.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        - Can easily be integrated in your own Apps for use by your customers.
      </Text>
    </Accordion.Panel>
  </Accordion.Item>

  {/* About TLS Accordion */}
  <Accordion.Item value="abouttls">
    <Accordion.Control>About TLS Certificates (SSL)</Accordion.Control>
    <Accordion.Panel>
      <Text style={{ textAlign: 'justify' }}>
        UrlyUp simplifies the process of managing TLS certificates, eliminating the need for developers to handle the complexities of encryption manually.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        Developers can run their applications locally on any HTTP port without worrying about setting up HTTPS.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        All internal connections between UrlyUp and the local host are securely encrypted using WireGuard, ensuring end-to-end privacy.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        Meanwhile, public connections to the UrlyUp-generated URL are strictly accessible over TLS, guaranteeing secure access for users.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        UrlyUp automatically manages the generation and renewal of TLS certificates, allowing developers to focus on their application without the burden of handling encryption logistics.
      </Text>
    </Accordion.Panel>
  </Accordion.Item>

  {/* About Self-hosting Accordion */}
  <Accordion.Item value="aboutselfhosting">
    <Accordion.Control>For Self-hosting</Accordion.Control>
    <Accordion.Panel>
      <Text style={{ textAlign: 'justify' }}>
        UrlyUp provides an ideal solution for self-hosting Free and Open Source Software (FOSS) applications by seamlessly publishing secure, public URLs that allow access to locally hosted applications.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        This eliminates the need for the application to run on an HTTPS-enabled port, simplifying the hosting process.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        UrlyUp handles TLS encryption for public access, ensuring that all communication to the published URLs is secure, while developers can continue using HTTP ports locally without modifying their application.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        Through its WireGuard integration, all internal traffic between the local host and UrlyUp is encrypted, providing end-to-end security.
      </Text>
      <Text style={{ textAlign: 'justify' }}>
        This setup is perfect for developers and enthusiasts looking to self-host FOSS applications securely while bypassing the complexities of public IPs, HTTPS configurations, and TLS certificate management.
      </Text>
    </Accordion.Panel>
  </Accordion.Item>


  <Accordion.Item value="support">
  <Accordion.Control>Support</Accordion.Control>
  <Accordion.Panel>
    <Text style={{ textAlign: 'justify' }}>
      UrlyUp is a free service, which means we rely on our community for support. If you need assistance, 
      the best place to get help is through our Discord support community. It's a great way to connect 
      with other users, share tips, and find solutions to common issues.
    </Text>
    <Text style={{ textAlign: 'justify' }}>
      Join the UrlyUp Support Community Channel on setip.io Discord here:{" "}
      <a href="https://discord.gg/aYEF5EbFPQ" target="_blank" rel="noopener noreferrer">
        https://discord.gg/aYEF5EbFPQ
      </a>
    </Text>
    <Text style={{ textAlign: 'justify' }}>
      Please note that since UrlyUp is a free service, we do not provide dedicated support, and all assistance 
      is community-driven. Feel free to join and ask questions, but also consider helping others when you can!
    </Text>
  </Accordion.Panel>
</Accordion.Item>


</Accordion>

          
        </Card>

        <footer className={classes.footer}>
  &copy; <a href="https://setip.io" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
    Nicolas Fodor - 2021-2024
  </a> - All rights reserved.
</footer>

      </Container>
    </MantineProvider>
  );
}

export default App;