import { Helmet } from "react-helmet";

const SEO = ({ userEmail }) => (
  <Helmet>
    {/* Basic Meta Tags */}
    <title>UrlyUp - Secure Public URLs</title>
    <meta
      name="description"
      content="UrlyUp lets you publish early from your own computer or any host, anywhere. No need for a public IP. TLS/SSL is automated."
    />
    <meta
      name="keywords"
      content="UrlyUp, secure URL, public URL, localhost, HTTPS, deployment, debugging, web development, backend development"
    />
    <meta name="author" content="SETIP.IO" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />

    {/* Open Graph Metadata */}
    <meta property="og:title" content="UrlyUp - Secure Public URLs" />
    <meta
      property="og:description"
      content="UrlyUp lets you publish early from your own computer or any host, anywhere. No need for a public IP. TLS/SSL is automated."
    />
    <meta property="og:image" content="https://urlyup.com/og-image.png" />
    <meta property="og:url" content="https://urlyup.com" />
    <meta property="og:type" content="website" />

    {/* Twitter Card Metadata */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="UrlyUp - Secure Public URLs" />
    <meta
      name="twitter:description"
      content="UrlyUp lets you publish early from your own computer or any host, anywhere. No need for a public IP. TLS/SSL is automated."
    />
    <meta name="twitter:image" content="https://urlyup.com/twitter-image.png" />

    {/* Canonical URL */}
    <link rel="canonical" href="https://urlyup.com" />

    {/* Favicon */}
    <link
      rel="icon"
      href="https://urlyup.com/favicon.ico"
      type="image/x-icon"
    />

    {/* Structured Data for RegisterAction */}
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "RegisterAction",
        name: "Register for UrlyUp Free Plan",
        target: `https://urlyup.com/free?email=${userEmail}`,
      })}
    </script>
  </Helmet>
);

export default SEO;
